import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { IProfile } from 'apps/early-stage-office/src/app/core/models/profile.interface';
import { IPoll } from 'apps/early-stage-office/src/app/core/services/polls.service';
import { ProfileState } from 'apps/early-stage-office/src/app/core/store/profile/profile.state';
import { IconComponent } from '../../../utility-modules/icon/components/icon/icon.component';
import { NgIf } from '@angular/common';

declare const gtag: Function;

@Component({
    selector: 'es-poll',
    templateUrl: './poll.component.html',
    styleUrls: ['./poll.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, IconComponent],
})
export class PollComponent implements OnInit {
    @Input() public poll: IPoll;

    public profile: IProfile;

    constructor(private store: Store) {}

    public ngOnInit(): void {
        this.profile = this.store.selectSnapshot(ProfileState.profile);
    }

    public onPollClick(): void {
        (window as any).dataLayer.push({
            event: 'poll-link-click',
            userId: this.profile?.user?.id,
            userName: `${this.profile?.user?.firstName} ${this.profile?.user?.lastName}`,
            userEmail: this.profile?.user?.email,
            poll: this.poll.title,
        });
    }
}
