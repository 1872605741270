<a class="profile-item" [ngClass]="{ 'is-link': canExpand }" (click)="canExpand ? toggleExpand($event) : null" [routerLink]="canRoute() ? role.route : null">
    <div class="profile-item__content" *ngIf="profile$ | async as profile">
        <p class="profile-item__name">{{ profile.user.firstName }} {{ profile.user.lastName }}</p>
        <p class="profile-item__role">
            {{ role?.roleName }}
        </p>
        <ng-container *ngIf="franchise$ | async as franchise">
            <p class="profile-item__franchise truncate" *ngIf="hasMultipleFranchises || role?.role === 'ROLE_SUPER_ADMIN'">#{{ franchise.id }} - {{ getFranchiseName(franchise) }}</p>
        </ng-container>
    </div>

    <div class="profile-item__icon profile-item__icon--{{ role.role.toLowerCase() }}">
        <ng-container *ngIf="role?.roleName !== 'Superadmin' && role?.roleName !== 'Admin'; else godIcon">
            <es-icon icon="user"></es-icon>
        </ng-container>
        <ng-template #godIcon>
            <es-icon icon="god"></es-icon>
        </ng-template>
        <div class="profile-item__icon-dot" [ngStyle]="{ 'background-color': role?.color || '#d2d2d2' }"></div>
    </div>

    <span class="profile-item__arrow" [ngClass]="{ 'is-active': expand$ | async, 'is-mobile': isMobile$ | async }">
        <es-icon icon="chevron-down-small" [width]="16" [height]="16"></es-icon>
    </span>
</a>
