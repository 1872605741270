import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { LayoutState } from '../../../../core/store/layout/layout.state';
import { fromEvent, Observable } from 'rxjs';
import { MenuExpand } from '../../../../core/store/layout/layout.actions';
import { ProfileState } from 'apps/early-stage-office/src/app/core/store/profile/profile.state';
import { IProfileRole } from 'apps/early-stage-office/src/app/core/models/profile.interface';
import { fadeAnimation, headerProfileAnimation } from 'apps/early-stage-office/src/app/core/animations';
import { WithDestroyComponent } from '../../../../core/abstract/abstract-with-destroy-component';
import { takeUntil } from 'rxjs/operators';
import { ProfileNavComponent } from './profile-nav/profile-nav.component';
import { ProfileListComponent } from './profile-list/profile-list.component';
import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { ExpandProfileItemComponent } from './expand-profile-item/expand-profile-item.component';

@Component({
    selector: 'es-header-profile',
    templateUrl: './header-profile.component.html',
    styleUrls: ['./header-profile.component.scss'],
    animations: [headerProfileAnimation, fadeAnimation],
    standalone: true,
    imports: [ExpandProfileItemComponent, NgClass, NgIf, ProfileListComponent, ProfileNavComponent, AsyncPipe],
})
export class HeaderProfileComponent extends WithDestroyComponent {
    @ViewChild('headerProfile', { static: true }) public headerProfile: ElementRef;

    @Select(ProfileState.currentRole) public currentRole$: Observable<IProfileRole>;
    @Select(LayoutState.menuExpand) public expand$: Observable<boolean>;
    @Select(LayoutState.isMobile) public isMobile$: Observable<boolean>;

    @Input() public canExpand: boolean = true;

    constructor(private store: Store) {
        super();
    }

    public ngOnInit(): void {
        fromEvent(document, 'click')
            .pipe(takeUntil(this.destroy$))
            .subscribe(e => {
                this.closeOutside(e as MouseEvent);
            });
    }

    public closeOutside(event: MouseEvent): void {
        if (!this.store.selectSnapshot(LayoutState.menuExpand)) {
            return;
        }

        const canClose: boolean | number = event.composedPath().indexOf(this.headerProfile.nativeElement);

        if (canClose === -1) {
            this.store.dispatch(new MenuExpand({ isExpand: false }));
        }
    }

    public closeExpand(): void {
        this.store.dispatch(new MenuExpand({ isExpand: false }));
    }
}
