import { Directive, ElementRef } from '@angular/core';

@Directive({
    selector: '[esMainContent]',
    standalone: true,
})
export class MainContentDirective {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    constructor(public elementRef: ElementRef<HTMLElement>) {}
}
