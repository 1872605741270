import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { ProfileState } from '../../../../../core/store/profile/profile.state';
import { Observable } from 'rxjs';
import { IProfile } from '../../../../../core/models/profile.interface';
import { LayoutState } from 'apps/early-stage-office/src/app/core/store/layout/layout.state';
import { ToggleMenuExpand } from 'apps/early-stage-office/src/app/core/store/layout/layout.actions';
import { ProfileItemComponent } from '../profile-item/profile-item.component';
import { Router, RouterLink } from '@angular/router';
import { ISingleFranchise } from 'apps/early-stage-office/src/app/core/models/franchise.interface';
import { IconComponent } from '../../../../../utility-modules/icon/components/icon/icon.component';
import { AsyncPipe, NgClass, NgIf, NgStyle } from '@angular/common';

@Component({
    selector: 'es-expand-profile-item',
    templateUrl: './expand-profile-item.component.html',
    styleUrls: ['../profile-item/profile-item.component.scss', './expand-profile-item.component.scss'],
    standalone: true,
    imports: [NgClass, RouterLink, NgIf, IconComponent, NgStyle, AsyncPipe],
})
export class ExpandProfileItemComponent extends ProfileItemComponent implements OnInit {
    @Select(ProfileState.profile) public profile$: Observable<IProfile>;
    @Select(LayoutState.isMobile) public isMobile$: Observable<boolean>;
    @Select(LayoutState.siteContextFranchise) public franchise$: Observable<ISingleFranchise>;
    @Select(LayoutState.menuExpand) public expand$: Observable<boolean>;

    @Input() public online: boolean;
    @Input() public canExpand: boolean = true;

    @Output() public toggle: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(
        protected store: Store,
        protected cd: ChangeDetectorRef,
        protected router: Router
    ) {
        super(store, cd, router);
    }

    public toggleExpand(event: MouseEvent) {
        const profile = this.store.selectSnapshot(ProfileState.profile);
        const isMobile = this.store.selectSnapshot(LayoutState.isMobile);

        if ((profile && profile.user.roles.length > 1) || !isMobile) {
            event.stopPropagation();
            event.preventDefault();
            this.store.dispatch(new ToggleMenuExpand());

            const menuExpand = this.store.selectSnapshot(LayoutState.menuExpand);
            this.toggle.emit(menuExpand);
        }
    }

    public canRoute(): boolean {
        const profile = this.store.selectSnapshot(ProfileState.profile);
        const isMobile = this.store.selectSnapshot(LayoutState.isMobile);

        if (!this.canExpand) {
            return true;
        }

        if ((profile && profile.user.roles.length > 1) || !isMobile) {
            return false;
        }

        if (!this.role?.roleFranchises?.some(rf => rf.status === true)) {
            return false;
        }

        return true;
    }
}
