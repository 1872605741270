<nav class="profile-nav">
    <ul class="profile-nav__list">
        <li class="profile-nav__item" *ngIf="showProfile">
            <a class="profile-nav__link link" [routerLink]="['', { outlets: { menu: null, primary: ['profil'] } }]" routerLinkActive="link">Mój profil</a>
        </li>
        <li class="profile-nav__item">
            <a class="profile-nav__link" [ngClass]="{ link: !(isMobile$ | async) }" (click)="logout()">Wyloguj</a>
        </li>
    </ul>
</nav>
