import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { CloseMenu } from '../../../../../core/store/layout/layout.actions';
import { IProfileRole } from '../../../../../core/models/profile.interface';
import { SetCurrentRole } from 'apps/early-stage-office/src/app/core/store/profile/profile.actions';
import { Router, RouterLink } from '@angular/router';
import { ProfileService } from '../../../../../core/authentication/profile.service';
import { ProfileState } from 'apps/early-stage-office/src/app/core/store/profile/profile.state';
import { ISingleFranchise } from 'apps/early-stage-office/src/app/core/models/franchise.interface';
import { IconComponent } from '../../../../../utility-modules/icon/components/icon/icon.component';
import { NgClass, NgIf, NgStyle } from '@angular/common';
import { TooltipDirective } from '@early-stage/components';

@Component({
    selector: 'es-profile-item',
    templateUrl: './profile-item.component.html',
    styleUrls: ['./profile-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [RouterLink, TooltipDirective, NgClass, NgIf, IconComponent, NgStyle],
})
export class ProfileItemComponent implements OnInit {
    @Input() public role: IProfileRole;

    public hasMultipleFranchises: boolean;
    public isRoleActive: boolean;
    public isRoleCurrent: boolean;
    public disabledMessage: string;

    constructor(
        protected store: Store,
        protected cd: ChangeDetectorRef,
        protected router: Router
    ) {}

    public ngOnInit() {
        this.hasMultipleFranchises = this.store.selectSnapshot(ProfileState.hasMultipleFranchises);
        this.isRoleCurrent = this.store.selectSnapshot(ProfileState.isRoleCurrent(this.role));
        this.isRoleActive = ProfileService.isRoleActive(this.role);
        this.disabledMessage = this.getDisabledMessage(this.role);
    }

    public onClick(): void {
        this.store.dispatch(new SetCurrentRole({ role: this.role }));
        this.router.navigate([this.role.route[0], { outlets: { primary: null } }], { skipLocationChange: false }).then(() => {
            this.router.navigate(['', { outlets: { menu: null } }], { replaceUrl: true });
        });

        if (this.role.roleFranchises?.some(r => r.status)) {
            setTimeout(() => {
                this.store.dispatch(new CloseMenu());
            });
        }

        this.cd.markForCheck();
    }

    public getDisabledMessage(role: IProfileRole): string {
        if (!role) {
            return '';
        }

        if (role.role === 'ROLE_SUPER_ADMIN') {
            return '';
        }

        if (this.isRoleActive) {
            return '';
        }

        switch (this.role.role) {
            case 'ROLE_FRANCHISEE':
                return 'Twoje konto nie ma przypisanej żadnej franczyzy, <br>skontaktuj się z administratorem.';
            case 'ROLE_LEADER':
            case 'ROLE_SECRETARIAT':
                return 'Twoje konto nie ma przypisanej żadnej franczyzy.';
            default:
                return 'Twoje konto jest nieaktywne';
        }
    }

    public getFranchiseName(franchise: ISingleFranchise): string {
        // return 'Kowalska Anna';
        if (!franchise.franchiseUsers) {
            return '-';
        }

        if (franchise.franchiseUsers.length === 1) {
            return franchise?.franchiseUsers?.map(u => u.lastName + ' ' + u.firstName)[0];
        }
        return franchise?.franchiseUsers?.map(u => u.lastName).join(' / ');
    }
}
