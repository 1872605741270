<div class="header-profile" id="header-profile" #headerProfile>
    <es-expand-profile-item
        class="header-profile__main"
        expand="true"
        showName="true"
        [ngClass]="{ 'is-active': expand$ | async }"
        [role]="currentRole$ | async"
        [canExpand]="canExpand"></es-expand-profile-item>

    <div *ngIf="!(isMobile$ | async)">
        <div *ngIf="expand$ | async" class="header-profile__expand">
            <es-profile-list></es-profile-list>
            <es-profile-nav></es-profile-nav>
        </div>
    </div>

    <div *ngIf="isMobile$ | async">
        <ng-container *ngIf="expand$ | async">
            <div class="header-profile__expand" [@headerProfileAnimation]>
                <es-profile-list></es-profile-list>
            </div>
            <div class="overlay" [@fadeAnimation] (click)="closeExpand()" #overlay></div>
        </ng-container>
    </div>
</div>
