import { Component } from '@angular/core';
import { Select } from '@ngxs/store';
import { ProfileState } from '../../../../../core/store/profile/profile.state';
import { Observable } from 'rxjs';
import { IProfileRole } from '../../../../../core/models/profile.interface';
import { LayoutState } from 'apps/early-stage-office/src/app/core/store/layout/layout.state';
import { ProfileItemComponent } from '../profile-item/profile-item.component';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';

@Component({
    selector: 'es-profile-list',
    templateUrl: './profile-list.component.html',
    styleUrls: ['./profile-list.component.scss'],
    standalone: true,
    imports: [NgFor, NgIf, ProfileItemComponent, AsyncPipe],
})
export class ProfileListComponent {
    @Select(ProfileState.profileRoles) public roles$: Observable<IProfileRole[]>;
    @Select(ProfileState.currentRole) public currentRole$: Observable<IProfileRole>;
    @Select(LayoutState.isMobile) public isMobile$: Observable<boolean>;

    constructor() {}
}
