<div
    class="profile-item"
    [routerLink]="isRoleActive ? role.route : null"
    [escTooltip]="disabledMessage"
    [attr.data-test]="'profile-role-' + role.role.toLowerCase()"
    (click)="isRoleActive ? onClick() : null"
    [ngClass]="{
        'is-disabled': !isRoleActive,
        'is-active': isRoleCurrent
    }">
    <div class="profile-item__content">
        <p class="profile-item__role">
            <span>
                {{ role.roleName }}
                <span class="profile-item__franchise truncate" *ngIf="hasMultipleFranchises && role.franchise">
                    #{{ role.franchise?.franchise?.id }} - {{ getFranchiseName(role.franchise?.franchise) }}
                </span>
            </span>
        </p>
    </div>

    <div class="profile-item__icon profile-item__icon--{{ role.role.toLowerCase() }}">
        <ng-container *ngIf="role.roleName !== 'Superadmin' && role.roleName !== 'Admin'; else godIcon">
            <es-icon icon="user"></es-icon>
        </ng-container>
        <ng-template #godIcon>
            <es-icon icon="god"></es-icon>
        </ng-template>

        <div class="profile-item__icon-dot" [ngStyle]="{ 'background-color': role.color }"></div>
    </div>
</div>
