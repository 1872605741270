import { Component, Input } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Logout } from 'apps/early-stage-office/src/app/core/store/auth/auth.actions';
import { Observable } from 'rxjs';
import { LayoutState } from 'apps/early-stage-office/src/app/core/store/layout/layout.state';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { AsyncPipe, NgClass, NgIf } from '@angular/common';

@Component({
    selector: 'es-profile-nav',
    templateUrl: './profile-nav.component.html',
    styleUrls: ['./profile-nav.component.scss'],
    standalone: true,
    imports: [NgIf, RouterLinkActive, RouterLink, NgClass, AsyncPipe],
})
export class ProfileNavComponent {
    @Input() public showProfile: boolean = true;

    @Select(LayoutState.isMobile) public isMobile$: Observable<boolean>;

    constructor(
        public store: Store,
        public router: Router
    ) {}

    public logout(): void {
        this.closeMenu();
        this.store.dispatch(new Logout({ navigate: true, sendLogout: true }));
    }

    public closeMenu(): void {
        setTimeout(() => {
            this.router.navigate([{ outlets: { menu: null } }]);
        });
    }
}
