<div class="poll" *ngIf="poll">
    <div class="poll__icon-column">
        <div class="poll__icon">
            <es-icon [icon]="poll.icon" [width]="36" [height]="48"></es-icon>
        </div>
    </div>
    <div class="poll__box-column">
        <div class="poll__content-column">
            <p class="poll__title">{{ poll.title }}</p>
            <p class="poll__text">{{ poll.text }}</p>
        </div>
        <div class="poll__button-column" *ngIf="poll.button">
            <a [href]="poll.button?.url" (click)="onPollClick()" class="poll__button esc-button is-secondary" target="_blank">{{ poll.button?.title }}</a>
        </div>
    </div>
</div>
