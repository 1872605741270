import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ComponentFactoryResolver,
    ComponentRef,
    ElementRef,
    EmbeddedViewRef,
    Inject,
    Injector,
    OnDestroy,
    OnInit,
    Type,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';

import { Observable } from 'rxjs';

import { IEnvironment } from 'apps/early-stage-office/src/environments/environment.interface';
import { Select, Store } from '@ngxs/store';
import { LayoutState } from 'apps/early-stage-office/src/app/core/store/layout/layout.state';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { WithDestroyComponent } from 'apps/early-stage-office/src/app/core/abstract/abstract-with-destroy-component';
import { takeUntil } from 'rxjs/operators';
import { ProfileState } from '../../../core/store/profile/profile.state';
import { TRoleName } from 'apps/early-stage-office/src/app/core/models/role.interface';
import { NavigationComponent } from '../navigation/navigation.component';
import { HeaderProfileComponent } from './header-profile/header-profile.component';
import { AsyncPipe, NgIf } from '@angular/common';
import { IconComponent } from '@early-stage/components';
import { CdkPortalOutlet, ComponentPortal } from '@angular/cdk/portal';
import { ContextMenuComponent } from '../context-menu/context-menu.component';

type THeaderComponent = { component: Type<unknown>; roles: TRoleName[] };

@Component({
    selector: 'es-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [RouterLink, NgIf, IconComponent, HeaderProfileComponent, NavigationComponent, AsyncPipe, CdkPortalOutlet],
})
export class HeaderComponent extends WithDestroyComponent implements OnInit, OnDestroy, AfterViewInit {
    public menuDynamicItems: Array<Type<unknown>>;
    public menuComponentRefs: ComponentRef<unknown>[] = [];

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public headerDynamicItems: Array<THeaderComponent>;
    public headerComponentRefs: ComponentRef<unknown>[] = [];
    public isMobile: boolean = false;

    @Select(LayoutState.isMobile) public isMobile$: Observable<boolean>;
    @Select(ProfileState.isUserInactive) public isUserInactive$: Observable<boolean>;

    @ViewChild('container', { static: false }) public headerContainer: ElementRef;
    @ViewChild('dynamicComponentContainer', { read: ViewContainerRef })
    dynamicComponentContainer: ViewContainerRef;
    @ViewChild('menuContainer', { static: false }) public menuContainer: ElementRef;

    constructor(
        @Inject('ENV') public env: IEnvironment,
        public cd: ChangeDetectorRef,
        private route: ActivatedRoute,
        private componentFactoryResolver: ComponentFactoryResolver,
        private injector: Injector,
        public router: Router,
        private store: Store
    ) {
        super();
    }

    public ngAfterViewInit(): void {
        setTimeout(() => {
            if (this.menuDynamicItems) {
                this.headerComponentRefs = [];

                this.menuDynamicItems.forEach(element => {
                    this.createDynamicComponent(element, this.menuContainer);
                });
            }

            if (this.headerDynamicItems) {
                this.headerComponentRefs = [];

                this.headerDynamicItems.forEach((element: THeaderComponent) => {
                    const isComponentWithRoles = (v: THeaderComponent): v is { component: Type<unknown>; roles: TRoleName[] } =>
                        (v as { component: Type<unknown>; roles: TRoleName[] }).roles !== undefined;

                    if (isComponentWithRoles(element)) {
                        const hasRole = this.store.selectSnapshot(ProfileState.someRole(element.roles));
                        if (hasRole) {
                            this.createDynamicComponent(element.component, this.headerContainer);
                        }
                    } else {
                        this.createDynamicComponent(element, this.headerContainer);
                    }
                });
            }
        });
    }

    public ngOnInit() {
        this.headerDynamicItems = this.route.snapshot.data.header;
        this.menuDynamicItems = this.route.snapshot.data.menuComponents;

        this.isMobile$.pipe(takeUntil(this.destroy$)).subscribe(isMobile => {
            this.isMobile = isMobile;
            this.cd.detectChanges();
        });
    }

    public ngOnDestroy() {
        super.ngOnDestroy();

        for (const headerComponentRef of this.headerComponentRefs) {
            headerComponentRef.destroy();
        }
    }

    public createDynamicComponent(element: Type<any>, container: ElementRef): void {
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(element);
        const componentRef = componentFactory.create(this.dynamicComponentContainer.injector);
        this.dynamicComponentContainer.insert(componentRef.hostView);
    }

    public openSidebar(): void {
        this.router.navigate([{ outlets: { menu: ['glowne'] } }]);
    }
}
