import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { IPoll } from 'apps/early-stage-office/src/app/core/services/polls.service';
import { DictionaryState } from 'apps/early-stage-office/src/app/core/store/dictionary/dictionary.state';
import { IEnvironment } from 'apps/early-stage-office/src/environments/environment.interface';
import { PollComponent } from '../poll/poll.component';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';

@Component({
    selector: 'es-polls-container',
    templateUrl: './polls-container.component.html',
    styleUrls: ['./polls-container.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, NgFor, PollComponent, AsyncPipe],
})
export class PollsContainerComponent implements OnInit {
    @Select(DictionaryState.polls) public polls$: Observable<IPoll[]>;
    constructor(@Inject('ENV') public env: IEnvironment) {}

    public ngOnInit(): void {}
}
